import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Page Not Found" />
      <div className="container mt-6 mb-6">
        <Header/>
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center" style={{minHeight: "50vh", textAlign: "center"}}>
          <h1 className="title is-1">404: Not Found</h1>
          <p>You tried to visit a page that doesn&#39;t exist... the sadness. This site has lots of other good pages, though!</p>
          <Link to={"/"} className="has-text-warning-dark mt-6">CLICK HERE to go to one!</Link>
        </div>

      </div>


    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
